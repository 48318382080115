<template>
  <div class="docsify-editor">
    <Toolbar
      v-if="!hideToolBar"
      class="docsify-editor-toolbar"
      :editor="editor"
      :defaultConfig="toolbarConfig"
      :mode="editorMode"
    />
    <Editor
      class="docsify-editor-engine"
      v-model="html"
      :defaultConfig="editorConfig"
      :mode="editorMode"
      @onCreated="onCreated"
      @onChange="onChange"
      @customPaste="(editor, $event) => customPaste(editor, $event)"
    />
  </div>
</template>
<script>
import '@wangeditor/editor/dist/css/style.css'
import { Boot } from '@wangeditor/editor'
import { Editor, Toolbar } from '@wangeditor/editor-for-vue'
import markdownModule from '@wangeditor/plugin-md'
Boot.registerModule(markdownModule)

const COMMENT_PATTERN = /^\(([\s\S])*?\d:[0-5]\d:[0-5]\d/gm
const ENTER_PATTERN = /[\f\r\t\n]/gm

export default {
  components: { Editor, Toolbar },
  name: 'DocsifyEditor',
  data() {
    return {
      editor: null,
      html: '<p></br></p>',
      toolbarConfig: {},
      editorConfig: { placeholder: '请输入内容...' },
      pasting: false
    }
  },
  props: {
    value: {
      type: String,
      default: ''
    },
    hideToolBar: {
      type: Boolean,
      default: false
    },
    editorMode: {
      type: String,
      default: 'default' // or 'simple'
    },
    clearFormatOnPaste: {
      type: Boolean,
      default: false
    }
  },
  watch: {
    html: {
      handler(val) {
        this.$emit('input', val)
      }
    }
  },
  methods: {
    onCreated(editor) {
      this.editor = Object.seal(editor) // 一定要用 Object.seal() ，否则会报错
      this.setHtml(this.value)
      console.log({ 'this.editor': this.editor })
    },
    onChange(editor) {
      console.log('onChange123', { editor, 'this.html': this.html })
    },
    clear() {
      this.editor.clear()
    },
    setHtml(html) {
      console.log('setHtml', { html, 'this.pasting': this.pasting })
      // when pasting, the editor will be changed, so we need to set the scroll to zero
      this.$nextTick(() => document.querySelector('.w-e-scroll')?.scrollTo(0, 0))
      this.editor.clear()
      if (!html.startsWith('<p')) {
        html = `<p>${html}</p>`
      }
      this.editor.dangerouslyInsertHtml(html)
      this.pasting?.call?.()
      this.pasting = false
    },
    scrollToElem(id) {
      this.editor.scrollToElem(id)
    },
    customPaste(editor, event) {
      console.log('customPaste', { editor, event })
      if (!this.clearFormatOnPaste) {
        return true
      }
      let text = event.clipboardData.getData('text/plain') // 获取粘贴的纯文本
      console.log('获取粘贴的纯文本', { text })
      text = text
        .replaceAll(COMMENT_PATTERN, '')
        .replaceAll(ENTER_PATTERN, '')
        .replaceAll(')', '\n')
      if (!text.startsWith('<')) {
        text = `<p>${text}</p>`
      }
      console.log('获取文本', { text })
      this.html = text

      // 阻止默认的粘贴行为
      event.preventDefault()
      event.stopImmediatePropagation()
      event.stopPropagation()
      return false
    }
  },
  mounted() {
    this.$emit('editor', this.editor)
  },
  beforeDestroy() {
    this.editor?.destroy() // 组件销毁时，及时销毁编辑器
  }
}
</script>

<style lang="stylus" scoped>
.docsify-editor {
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;
  cursor: auto;

  .docsify-editor-toolbar {
    width: 100%;
    border-bottom: 1px solid #ccc;
  }

  .docsify-editor-engine {
    flex: 1;
    width: 100%;
    display: flex;
    flex-direction: column;
  }
}

/deep/ .w-e-text-container {
  word-break: break-all;
  flex: 1;

  .w-e-scroll {
    position: absolute;
    width: 100%;
    height: 100%;

    .w-e-textarea-3 {
      min-height: 60vh;
    }
  }
}

/deep/ .w-e-full-screen-container {
  .docsify-editor-toolbar {
  }
}

/deep/ .w-e-scroll {
  overflow-y: overlay !important;
  // padding-right: 12px;
}

/deep/ .w-e-scroll:hover {
  // padding-right: 0px !important;
}

/deep/ .w-e-scroll::-webkit-scrollbar {
  width: 0px;
}

/deep/.w-e-scroll:hover::-webkit-scrollbar {
  width: 12px;
}
</style>
